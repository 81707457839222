import React, { FC, useEffect, useState } from "react";
import Modal from "@material-ui/core/Modal";
import { NewsletterLayout } from "types";
import { Product } from "../Content/Product/PriceAlertPopup";

export const getModalStyle = () => {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
    outline: "none",
    overflow: "auto",
  };
};

export type CommonModalProps = {
  handleClose?: () => void;
  open: boolean;
  containerClass?: string;
  dataTest?: string;
};

const CommonModal: FC<CommonModalProps> = ({ containerClass, dataTest, open, handleClose, children }) => {
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = useState(getModalStyle());
  const [allowOffAreaClose, setAllowOffAreaClose] = useState(false);

  const handleOffAreaClose = () => {
    if (allowOffAreaClose) {
      handleClose();
    }
  };

  useEffect(() => {
    if (open) {
      setTimeout(() => {
        setAllowOffAreaClose(true);
      }, 3000);
    } else {
      setAllowOffAreaClose(false);
    }
  }, [open, setAllowOffAreaClose]);

  return (
    <Modal
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      open={open}
      onClose={handleOffAreaClose}
    >
      <div style={modalStyle} className={containerClass} data-test={dataTest}>
        {children}
      </div>
    </Modal>
  );
};

export default CommonModal;
