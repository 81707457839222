import React, { FC } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import useTranslation from "next-translate/useTranslation";
import environment from "../../environment";
import { TheCycleverseTheme } from "src/theme";
import SubscribeForm from "./SubscribeForm";

const useStyles = makeStyles((theme: TheCycleverseTheme) => ({
  highlightColor: {
    color: theme.hightlightColorSecondary,
  },
  highlightColor2: {
    color: theme.hightlightColor,
  },
  saleColor: {
    color: theme.saleColor,
  },
  headlineText: {
    fontFamily: theme.highlightFontFamily,
    fontWeight: 600,
    fontSize: 28, //"xx-large",
    lineHeight: 1.2,
  },
  headlineText2: {
    fontFamily: theme.highlightFontFamily,
    fontWeight: 600,
    fontSize: "x-large",
    lineHeight: 1.2,
    marginBottom: theme.spacing(1),
  },
  horizontalSpacingLarge: {
    marginBottom: theme.spacing(4),
  },
  horizontalSpacingSmall: {
    marginBottom: theme.spacing(2),
  },
  textLeft: {
    textAlign: "left",
    fontWeight: 300,
  },
  breakSpan: {
    "& span": {
      display: "inline-block",
      whiteSpace: "pre-wrap",
    },
  },
}));

export type PriceAlarmSubscribeProps = {
  afterSubscribe: () => void;
  close: () => void;
  page: string;
};

const PriceAlarmSubscribe: FC<PriceAlarmSubscribeProps> = (props) => {
  const { afterSubscribe, close, page } = props;
  const classes = useStyles();
  const { t } = useTranslation("common");

  return (
    <SubscribeForm
      page={page}
      mailchimpUrl={environment.mailchimpPriceAlarmUrl}
      afterSubscribe={afterSubscribe}
      close={close}
      showPrivacyNote={true}
    >
      <Typography
        align="center"
        variant="h3"
        className={`${classes.breakSpan} ${classes.saleColor} ${classes.headlineText} ${classes.horizontalSpacingLarge}`}
      >
        <span>{t("newsletterHeadline2")}</span>
        <span>{t("newsletterHeadline3")}</span>
      </Typography>

      <Typography align="center" variant="body1" className={`${classes.horizontalSpacingSmall} ${classes.textLeft}`}>
        {t("newsletterCopy4")}
      </Typography>

      <Typography align="center" variant="body1" className={`${classes.highlightColor2} ${classes.textLeft}`}>
        {t("newsletterCTA")}
      </Typography>
    </SubscribeForm>
  );
};

export default PriceAlarmSubscribe;
