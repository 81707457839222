import React, { FC } from "react";
import { makeStyles } from "@material-ui/core/styles";
import useTranslation from "next-translate/useTranslation";
import environment from "../../environment";
import { TheCycleverseTheme } from "src/theme";
import SubscribeForm from "./SubscribeForm";
import { NewsletterLayout } from "types";
import Image from "next/image";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const useStyles = makeStyles((theme: TheCycleverseTheme) => ({
  teaserContainerBackground: {
    "& .FX-SubscribeForm-Container": {
      backgroundColor: "#F5EFFC",
    },
  },
  teaserContainerBackgroundTests: {
    "& .FX-SubscribeForm-Container": {
      backgroundColor: "#F2F2F0",
    },
  },

  cta1: {
    fontSize: 23,
  },
  cta2: {
    fontSize: 23,
    lineHeight: "23px",
    fontFamily: theme.highlightFontFamily,
    color: theme.saleColor,
    fontWeight: 600,
  },
  text: {
    fontSize: 24,
    [theme.breakpoints.down("xs")]: {
      fontSize: 20,
    },
    fontFamily: theme.highlightFontFamily,
    fontWeight: 600,
  },
  textTests: {
    fontSize: 24,
    lineHeight: "28px",
    "& b": {
      fontWeight: 600,
    },
  },
  bulletpoints: {
    "& ul": {
      padding: 0,
      margin: 0,
      marginBottom: 17,
      marginTop: 17,
      listStyleType: "none",
      textIndent: "-20px",
      paddingLeft: "20px",
    },
    "& li::before": {
      content: '""',
      display: "inline-block",
      height: 20,
      width: 20,
      backgroundSize: 20,
      backgroundImage: 'url("/images/BulletpointHighlight.svg")',
      backgroundRepeat: "no-repeat",
      marginRight: 7,
    },
  },
  headline: {
    color: theme.saleColor,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 900,
    fontSize: 60,
    lineHeight: "60px",
  },
  headlineSmaller: {
    color: theme.saleColor,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 900,
    fontSize: 45,
    lineHeight: "45px",
  },
  subheadline: {
    lineHeight: "32px",
    [theme.breakpoints.down("xs")]: {
      lineHeight: "24px",
    },
  },

  newsLetterContainer: {
    width: "100%",
    maxWidth: 450,
    backgroundColor: theme.palette.primary.main,
    margin: "auto",
  },

  newsLetterHeadLine: {
    color: theme.newsletterOrangeTitle,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 900,
    fontSize: "50px",
    lineHeight: "72px",
    textAlign: "center",
  },

  newsLetterSubHeadLine: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 300,
    fontSize: "24px",
    lineHeight: "28px",
    textAlign: "center",
  },

  newsLetterTextOne: {
    fontFamily: theme.highlightFontFamily,
    fontWeight: 900,
    fontSize: "24px",
    lineHeight: "26px",
    marginLeft: "10px",
  },

  newsLetterTextTwo: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 300,
    fontSize: "18px",
    lineHeight: "24px",
    marginLeft: "30px",
  },

  bulletpointContainer: {
    display: "flex",
    flexDirection: "column",
    marginBottom: "7px",
  },

  newsLetterContentContainer: {
    marginTop: "20px",
    marginBottom: "20px",
  },

  titleContainer: {
    display: "flex",
    alignItems: "center",
  },
}));

export type NewsletterSubscribeProps = {
  afterSubscribe: () => void;
  close: () => void;
  layout?: NewsletterLayout;
  page: string;
};

const NewsletterSubscribe: FC<NewsletterSubscribeProps> = (props) => {
  const { afterSubscribe, close, layout, page } = props;
  const classes = useStyles();
  const { t } = useTranslation("newsletter");

  const isSmallDevice = useMediaQuery("(max-width: 440px)");

  return (
    <div>
      <SubscribeForm
        className={
          layout == "tests"
            ? classes.teaserContainerBackgroundTests
            : layout == "newsLetter"
            ? classes.newsLetterContainer
            : classes.teaserContainerBackground
        }
        mailchimpUrl={environment.mailchimpNewsletterUrl}
        afterSubscribe={afterSubscribe}
        close={close}
        page={page}
        showPrivacyNote={true}
        ctaElement={
          <div>
            <div className={classes.cta1}>{layout == "newsLetter" ? t("newsLetterText4") : t("cta1")}</div>
            <div className={classes.cta2}>{t("cta2")}</div>
          </div>
        }
      >
        {layout == "tests" ? (
          <div className={`${classes.textTests} ${classes.bulletpoints}`}>
            <div className={`${classes.headlineSmaller}`}>{t("testsHeadline")}</div>
            <div className={`${classes.subheadline}`}>
              <b>{t("testsSubHeadline_1")}</b>
              {t("testsSubHeadline_2")}
            </div>
            <ul>
              <li>
                <b>{t("testsReason1_1")}</b>
                {t("testsReason1_2")}
              </li>
              <li>
                <b>{t("testsReason2_1")}</b>
                {t("testsReason2_2")}
              </li>
              <li>
                <b>{t("testsReason3_1")}</b>
                {t("testsReason3_2")}
              </li>
              <li>
                <b>{t("testsReason4_1")}</b>
                {t("testsReason4_2")}
              </li>
            </ul>
          </div>
        ) : layout == "newsLetter" ? (
          <div>
            <div className={classes.newsLetterHeadLine}>{t("headline")}</div>
            <div className={classes.newsLetterSubHeadLine}>{t("newsLetterText1")}</div>

            <div className={classes.newsLetterContentContainer}>
              <div className={classes.bulletpointContainer}>
                <div className={classes.titleContainer}>
                  <Image width={22} height={22} src={"/images/BulletpointHighlight.svg"} alt="Bullet point" />
                  <div className={classes.newsLetterTextOne}>{t("reason1")}</div>
                </div>
                <div className={classes.newsLetterTextTwo}> {t("newsLetterText3")}</div>
              </div>

              <div className={classes.bulletpointContainer}>
                <div className={classes.titleContainer}>
                  <Image width={22} height={22} src={"/images/BulletpointHighlight.svg"} alt="Bullet point" />
                  <div className={classes.newsLetterTextOne}>{t("reason2")}</div>
                </div>
                <div>
                  <div className={classes.newsLetterTextTwo}>{t("newsLetterText5")}</div>
                </div>
              </div>

              <div className={classes.bulletpointContainer}>
                <div className={classes.titleContainer}>
                  <Image width={22} height={22} src={"/images/BulletpointHighlight.svg"} alt="Bullet point" />
                  <div className={classes.newsLetterTextOne}>{t("reason3")}</div>
                </div>
                <div>
                  <div className={classes.newsLetterTextTwo}>{t("newsLetterText7")}</div>
                </div>
              </div>

              <div className={classes.bulletpointContainer}>
                <div className={classes.titleContainer}>
                  <Image
                    width={isSmallDevice ? 30 : 26}
                    height={isSmallDevice ? 30 : 26}
                    src={"/images/BulletpointHighlight.svg"}
                    alt="Bullet point"
                  />
                  <div className={classes.newsLetterTextOne}>{t("reason4")}</div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className={`${classes.text} ${classes.bulletpoints}`}>
            <div className={classes.headline}>{t("headline")}</div>
            <div className={classes.subheadline}>{t("subHeadline")}</div>
            <ul>
              <li>{t("reason1")}</li>
              <li>{t("reason2")}</li>
              <li>{t("reason3")}</li>
              <li>{t("reason4")}</li>
            </ul>
          </div>
        )}
      </SubscribeForm>
    </div>
  );
};

export default NewsletterSubscribe;
