import React, { FC } from "react";
import { makeStyles } from "@material-ui/core/styles";
import PriceAlarmSubscribe from "./PriceAlarmSubscribe";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import NewsletterSubscribe from "./NewsletterSubscribe";
import { TheCycleverseTheme } from "src/theme";
import { NewsletterLayout } from "types";
import CommonModal from "../common/CommonModal";

const useStyles = makeStyles((theme: TheCycleverseTheme) => ({
  paper: {
    overflow: "scroll",
    position: "absolute",
    width: "90%",
    maxWidth: 400,
    maxHeight: "80%",
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    display: "block",
  },
  closeButton: {
    textAlign: "right",
    position: "absolute",
    width: "100%",
    paddingTop: theme.globalMarginMobile,
    paddingRight: theme.globalMarginMobile,
    "& .MuiIconButton-root": {
      padding: 0,
    },
  },
}));

export type NewsletterModalProps = {
  afterSubscribe: () => void;
  handleClose?: () => void;
  open: boolean;
  variant: "priceAlertInterest" | "newsletter";
  layout?: NewsletterLayout;
  page: string;
};

const NewsletterModal: FC<NewsletterModalProps> = (props) => {
  const { open, handleClose, afterSubscribe, variant, layout, page } = props;
  const classes = useStyles();

  return (
    <CommonModal open={open} handleClose={handleClose} containerClass={classes.paper} dataTest="newsletter-container">
      <div className={classes.closeButton}>
        <IconButton color="inherit" aria-label="menu" onClick={handleClose} data-test="newsletter-close">
          <CloseIcon style={{ color: "#000" }} />
        </IconButton>
      </div>
      {variant == "priceAlertInterest" && (
        <PriceAlarmSubscribe page={page} afterSubscribe={afterSubscribe} close={handleClose} />
      )}
      {(variant == "newsletter" || !variant) && (
        <NewsletterSubscribe page={page} afterSubscribe={afterSubscribe} close={handleClose} layout={layout} />
      )}
    </CommonModal>
  );
};

export default NewsletterModal;
