import React, { FC, useCallback } from "react";
import { useEffectOnce, useEvent, useLifecycles, useTimeoutFn, useUnmountPromise } from "react-use";
import NewsletterModal from "./NewsletterModal";
import { NewsletterLayout } from "types";
import Analytics from "../../lib/analytics/analytics";
import { localStorageSet, localStorageGet, sessionStorageGet, sessionStorageSet } from "../../lib/storage";

const KEY_LASTPOPUPS = `newsletter-lastpopups`;
const KEY_REGISTERED = `newsletter-registered`;
const KEY_CLICK_COUNT = `newsletter-clicks`;
const KEY_POPUP_SHOWED = `newsletter-popup`;
const SHOW_POPUP_LONG_TIMEOUT = 60000;
const SHOW_POPUP_CLICK_COUNT = 2;
const SHOW_POPUP_CLICK_TIMEOUT = 3000;

const isUserRegistered = () => {
  return localStorageGet(KEY_REGISTERED, false);
};

const setUserRegistered = () => {
  localStorageSet(KEY_REGISTERED, true);
};

const popupAlreadyShowed = () => {
  const timestamps = localStorageGet(KEY_LASTPOPUPS, []);
  const hourTS = 60 * 60 * 1000;
  const dayTS = 24 * hourTS;
  const weekTS = 7 * dayTS;
  const now = Date.now();
  const popupCountLastSevenDays = timestamps.filter((ts) => ts > now - weekTS).length;
  const popupCountLastDay = timestamps.filter((ts) => ts > now - dayTS).length;
  const popupCountLastHour = timestamps.filter((ts) => ts > now - hourTS).length;

  if (popupCountLastSevenDays != timestamps.length) {
    localStorageSet(
      KEY_LASTPOPUPS,
      timestamps.filter((ts) => ts > now - 7 * dayTS)
    );
  }

  if (popupCountLastSevenDays >= 4) {
    return true;
  }

  if (popupCountLastDay >= 2) {
    return true;
  }

  if (popupCountLastHour >= 5) {
    return true;
  }

  return false;
};

const addPopupTimestamp = () => {
  const timestamps = localStorageGet(KEY_LASTPOPUPS, []);
  localStorageSet(KEY_LASTPOPUPS, [...timestamps, Date.now()]);
};

const setPopupShowedInSession = () => {
  sessionStorageSet(KEY_POPUP_SHOWED, 1);
};

const popupAlreadyShowedInSession = () => {
  return sessionStorageGet(KEY_POPUP_SHOWED, 0) >= 1;
};

const showPopup = (showNewsletter, page) => {
  return; // disabled popups because of bounce rate.

  if (document && document.hidden) {
    return; //noop
  }
  if (isUserRegistered()) {
    return; //noop
  }
  if (popupAlreadyShowed()) {
    return; //noop
  }
  if (popupAlreadyShowedInSession()) {
    return; //noop
  }
  showNewsletter(true);
  addPopupTimestamp();
  setPopupShowedInSession();
  Analytics.newsletterCTA(page);
};

const getClicks = () => {
  return sessionStorageGet(KEY_CLICK_COUNT, 0);
};

const countClick = () => {
  const newValue = getClicks() + 1;
  sessionStorageSet(KEY_CLICK_COUNT, newValue);
  return newValue;
};

const showPopupClickTrigger = (showNewsletter, page, mounted) => {
  if (getClicks() >= SHOW_POPUP_CLICK_COUNT) {
    return setTimeout(() => {
      const promise = new Promise((resolve) => {
        showPopup(showNewsletter, page);
        resolve(true);
      });
      mounted(promise);
    }, SHOW_POPUP_CLICK_TIMEOUT);
  }
};

const getWatchedElement = () => {
  if (typeof window !== "undefined") {
    return document?.getElementById("FX-MainLayout-Content");
  }
  return null;
};

export type NewsletterCTAProps = {
  layout: NewsletterLayout;
  page: string;
};

const NewsletterCTA: FC<NewsletterCTAProps> = (props) => {
  const { layout, page } = props;
  const [open, setOpen] = React.useState(false);
  const mounted = useUnmountPromise();

  const onClickEvent = useCallback(() => {
    countClick();
    showPopupClickTrigger(setOpen, page, mounted);
  }, [setOpen, page, mounted]);

  useEvent("click", onClickEvent, getWatchedElement());

  useEffectOnce(() => {
    showPopupClickTrigger(setOpen, page, mounted);
  });

  useTimeoutFn(() => {
    showPopup(setOpen, page);
  }, SHOW_POPUP_LONG_TIMEOUT);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <NewsletterModal
      variant="newsletter"
      layout={layout}
      open={open}
      page={page}
      handleClose={handleClose}
      afterSubscribe={() => setUserRegistered()}
    />
  );
};

export default NewsletterCTA;
